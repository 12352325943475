<style>
/* Set the page size to A4 and the orientation to landscape */
@page {
    size: A4 landscape;
}

/* Set the font size to 12px for printing */
@media print {
    body {
        font-size: 12px;
    }
}

#logo {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 200px;
    height: 100px;
}

#title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#no-border {
    border: none;
}

td,
th {
    border: 2px solid #000000;
    text-align: left;
    padding: 8px;
}

.remove_border tr th {
    border: none;
}

#table-list {
    margin-top: 80px;
}

#info {
    margin-top: 20px;
    width: 40%;
    float: left;
    margin-bottom: 20px;
}

.space-for-logo {
    width: 200px;
    height: 100px;
}
</style>

<template>
    <div>
        <!-- <img :src="logoSDN" id="logo" /> -->
        <p id="title">Stock Transfer Order - Handover</p>
        <div class="space-for-logo"></div>
        <div id="info">
            <table class="remove_border">
                <tr>
                    <th>STO ID</th>
                    <th>:</th>
                    <th>{{ stoCode }}</th>
                </tr>
                <tr>
                    <th>Handover Document</th>
                    <th>:</th>
                    <th>{{ handoverCode }}</th>
                </tr>
                <tr>
                    <th>Vehicle Number</th>
                    <th>:</th>
                    <th>{{ selectedVehicle.label }}</th>
                </tr>
                <tr>
                    <th>Driver</th>
                    <th>:</th>
                    <th>{{ selectedDriver.label }}</th>
                </tr>
                <tr>
                    <th>Handover Date</th>
                    <th>:</th>
                    <th>{{ formatDate(handoverDate) }}</th>
                </tr>
            </table>
        </div>
        <table id="table-list">
            <thead>
                <h1>Handovered Item</h1>
                <tr></tr>
                <tr>
                    <th style="vertical-align: middle; text-align: center;">Delivery Note Data</th>
                    <th style="vertical-align: middle; text-align: center;">Item Name</th>
                    <th style="vertical-align: middle; text-align: center;">HU</th>
                    <th style="vertical-align: middle; text-align: center;">Quantity</th>
                    <th style="vertical-align: middle; text-align: center;">Dest. Address</th>
                    <th style="vertical-align: middle; text-align: center;">Handed-over</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(tr, index) in dataLines" :key="index">
                    <td>{{ tr.delivery_note_data }}</td>
                    <td>{{ tr.item_name }}</td>
                    <td>{{ tr.item_unit }}</td>
                    <td>{{ tr.amount }}</td>
                    <td>{{ tr.dest_address }}</td>
                    <td>
                        <vs-checkbox :checked="checked.includes(tr.id)" v-model="tr.is_handedover"
                            :disabled="true"></vs-checkbox>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from "moment-timezone";
import logoSDN from "../../../assets/images/logo/logo-sdn.png";
import { debounce } from 'lodash';

export default {
    name: "PrintPdf",
    data() {
        return {
            table: this.tableDefaultState(),
            id: null,
            readonly: true,
            stoCode: null,
            handoverCode: null,
            handoverDate: null,
            dataLines: [
                {
                    id: 0,
                    delivery_note_data: "",
                    item_name: "",
                    item_unit: "",
                    amount: 0,
                    dest_address: "",
                    is_handedover: false,
                }
            ],
            checked: [],
            selectedVehicle: null,
            selectedDriver: null,
            logoSDN: logoSDN,
            showInfo: false,
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.print();
        }
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
            };
        },
        async print() {
            await this.getData()
            const printFunction = debounce(() => {
                window.print();
            }, 1000);
            printFunction();
        },
        async getData() {
            this.$vs.loading();
            let resp = await this.$http.get(
                "/api/wms/v1/outbound/sto-processing/handover-edit/" + this.id
            );
            if (resp.code == 200) {
                this.stoCode = resp.data.sto_code;
                this.handoverCode = resp.data.handover_code;
                this.selectedVehicle = resp.data.vehicle;
                this.selectedDriver = resp.data.driver;
                this.handoverDate = moment(resp.data.handover_date).toDate();
                this.dataLines = resp.data.data_lines;
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        },
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>
